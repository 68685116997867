import React, { useMemo } from 'react';
import { useTranslate, useListContext } from 'react-admin';
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";


import { RevenueAndExpensesChart } from './RevenueAndExpensesChart';
import { OPATChart } from './OPATChart';
import { OrdersDatagrid } from './OrdersDataGrid';
import { LaborCostDatagrid } from './LaborCostDataGrid';

Chart.register(CategoryScale);


const prepareRevenueAndExpencesChartData = (data, revenueTooltip, laborCostTooltip, lPercentageTooltip) => {
    const values = Object.values(data);

    return {
        labels: values.map(row => row.grouping_period),
        datasets: [
            {
                type: 'bar',
                label: revenueTooltip,
                data: values.map(row => row.revenue),
                backgroundColor: [
                  'rgba(75, 192, 192, 0.2)',
                ],
                borderColor: [
                  'rgba(75, 192, 192, 1)',
                ],
                yAxisID: 'y',
                borderWidth: 1
            } ,
            {
                type: 'bar',
                label: laborCostTooltip,
                data:  values.map(row => row.labor_cost),
                backgroundColor: [
                  'rgba(255, 99, 132, 0.2)',
                ],
                borderColor: [
                  'rgba(255, 99, 132, 1)',
                ],
                yAxisID: 'y',
                borderWidth: 1
            },
            {
              type: 'line',
              label: lPercentageTooltip,
              data:  values.map(row => row.l_percentage),
              borderColor: [
                'rgba(255, 204, 153, 1)'
              ],
              stack: 'combined',
              yAxisID: 'y2',
              borderWidth: 5
          }
        ]
    }
}


const prepareOpatChartData = (data, opatTooltip, manHoursTooltip) => {
  const values = Object.values(data);

  return {
      labels: values.map(row => row.grouping_period),
      datasets: [
          { 
              type: 'bar',
              label: manHoursTooltip,
              data: values.map(row => row.man_hours),
              backgroundColor: [
                'rgba(0, 128, 255, 0.2)',
              ],
              borderColor: [
                'rgba(0, 128, 255, 1)',
              ],
              stack: 'combined',
              yAxisID: 'y',
              borderWidth: 1,
          } ,
          {
              type: 'line',
              label: opatTooltip,
              data: values.map(row => row.opat),
              borderColor: [
                'rgba(255, 178, 102, 1)',
              ],
              stack: 'combined',
              yAxisID: 'y2',
              borderWidth: 5,
          }
      ]
  }
}


export const FinancialCharts = props => {
    const translate = useTranslate()
    const { filterValues } = useListContext();

    const isPeriodOneDay = filterValues['date_from'] === filterValues['date_to'];
    
    const revenueTooltip = translate("resources.financial-statements.barchart.revenue.label")
    const laborCostTooltip = translate("resources.financial-statements.barchart.labor-cost.label")
    const lPercentageTooltip = translate("resources.financial-statements.barchart.l-percentage.label")
    const opatTooltip = translate("resources.financial-statements.barchart.opat.label")
    const manHoursTooltip = translate("resources.financial-statements.barchart.man-hours.label")

    const revenueAndExpencesData = useMemo(() => {
          return prepareRevenueAndExpencesChartData((props && props.data && Object.keys(props.data).length !== 0) 
                                                     ? props.data[1]['financial_statement_data'] : {}, revenueTooltip, laborCostTooltip, lPercentageTooltip)
    },[props.data])

    const opatData = useMemo(() => {
          return prepareOpatChartData((props && props.data && Object.keys(props.data).length !== 0) 
                                       ? props.data[1]['opat_chart_data'] : {}, opatTooltip, manHoursTooltip)
    },[props.data])

    const orders = useMemo(() => {
        return (props && props.data && Object.keys(props.data).length !== 0) ? props.data[1]['orders'] : {}
    },[props.data])

    const laborCost = useMemo(() => {
        return (props && props.data && Object.keys(props.data).length !== 0) ? props.data[1]['labor_cost'] : {}
    },[props.data])

    if (Object.keys(props.data).length === 0){
      return null
    }  

    return (
          <>
            <RevenueAndExpensesChart chartData={revenueAndExpencesData}/>
            {isPeriodOneDay && <>
                <div align='center'><h1>{translate("resources.orders.name")}</h1></div>
                <OrdersDatagrid orders={orders}/>
                <div align='center'><h1>{translate("resources.financial-statements.barchart.labor-cost.label")}</h1></div>
                <LaborCostDatagrid laborCost={laborCost}/>
            </>}
            <OPATChart chartData={opatData}/>
          </>
    )
};
